// extracted by mini-css-extract-plugin
export var root = "PlasmicTestimonial-module--root--ZfJAR";
export var avatar = "PlasmicTestimonial-module--avatar--PYQ9K";
export var img = "PlasmicTestimonial-module--img--pRhHK";
export var __wab_imgSpacer = "PlasmicTestimonial-module--__wab_img-spacer--F-xNl";
export var svg = "PlasmicTestimonial-module--svg--orl8L";
export var freeBox__pEgVp = "PlasmicTestimonial-module--freeBox__pEgVp--XuhAq";
export var text__yBEha = "PlasmicTestimonial-module--text__yBEha--Vuq9E";
export var freeBox__tLvan = "PlasmicTestimonial-module--freeBox__tLvan--cyVfi";
export var text__w0Lp0 = "PlasmicTestimonial-module--text__w0Lp0--W5KM0";
export var text___179Xp = "PlasmicTestimonial-module--text___179Xp--exQam";