// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureCard-module--root--nMnHW";
export var root__long = "PlasmicFeatureCard-module--root__long--qoR-w";
export var freeBox__k9QvT = "PlasmicFeatureCard-module--freeBox__k9QvT--Ai0FJ";
export var freeBox__long__k9QvTooJ = "PlasmicFeatureCard-module--freeBox__long__k9QvTooJ--Twe8e";
export var freeBox__gzMw3 = "PlasmicFeatureCard-module--freeBox__gzMw3--ja5FZ";
export var svg__yubEf = "PlasmicFeatureCard-module--svg__yubEf--Ie0iZ";
export var freeBox__cbF9L = "PlasmicFeatureCard-module--freeBox__cbF9L--HOvYq";
export var freeBox__long__cbF9LOoJ = "PlasmicFeatureCard-module--freeBox__long__cbF9LOoJ--fJb9b";
export var slotTargetTitle = "PlasmicFeatureCard-module--slotTargetTitle--CqlrF";
export var slotTargetDescription = "PlasmicFeatureCard-module--slotTargetDescription--WfpI+";
export var slotTargetDescription__long = "PlasmicFeatureCard-module--slotTargetDescription__long--hZka9";
export var freeBox___6KTb = "PlasmicFeatureCard-module--freeBox___6KTb--nnKQD";
export var freeBox__long___6KTbooJ = "PlasmicFeatureCard-module--freeBox__long___6KTbooJ--9cjvQ";
export var svg___0DQoj = "PlasmicFeatureCard-module--svg___0DQoj--o+vmX";